<template>
    <div>
        <event-header
          :showBackButton="false" >
        </event-header>

        <div v-if="!isStarted && !currentEvent.hasErrors">
            <b-button class="btn-event" variant="primary" :class="{ 'inactif': !isLaunchable }" :disabled="!isLaunchable" @click="initEventRun">Commencer l'épreuve</b-button>
            <info-helper v-if="!isLaunchable">
                Seul le chef juge défini pour cette épreuve peut la commencer !<br/>Le briefing de la manche doit avoir été préalablement effectué
            </info-helper>
        </div>
        <div v-if="isStarted && !isCompleted">
            <b-modal
              title="Relancer l'épreuve"
              v-model = "confirmRestart"
              ok-variant="danger"
              ok-title="Oui"
              @ok = "razEvent"
              cancel-variant="secondary"
              cancel-title="Non"
            >
                <div class="text">
                    <div class="dialogTitle">Etes-vous certain de vouloir écraser tous les scores de cette épreuve et la relancer depuis le début ?</div>
                    <div class="dialogRemark">En choisissant "oui" les scores actuels seront immérédiablement effacés et tous les compétiteurs devront repasser.</div>
                </div>
            </b-modal>

            <div class="text">
                <div class="dialogTitle">L'épreuve est commencée !</div>
                <div>Voulez-vous la reprendre ?</div>
            </div>
            <b-button class="btn-event" variant="success" :to="{name:'EventRun'}">Continuer l'épreuve</b-button>
            <div class="text">
                <div class="dialogTitle">Ou la relancer depuis le début ?</div>
                <div class="warning"><strong>Attention</strong>Tous les scores déjà enregistrés pour tous les compétieurs déjà passés seront écrasés et tous les compétiteurs devront recommencer l'épreuve</div>
            </div>
            <b-button class="btn" variant="danger" :class="{ 'inactif': !isLaunchable }" :disabled="!isLaunchable" @click="confirmRestart=true">Relancer l'épreuve depuis le début</b-button>
            <info-helper v-if="!isLaunchable">
                Seul le chef juge défini pour cette épreuve peut décider de la recommencer !
            </info-helper>
        </div>

        <collapse-panel title="Sélection du staff pour l'épreuve" lazy collapsed>
            <detailed-staff-list
                  :canSelectRole="!currentEvent.isStarted"
                  :items="d_staffUsages"
                  :showShortname="true"
                  @roleChanged="roleChanged"                  
              >
              </detailed-staff-list>

            <form-field caption="Chef juge de l'épreuve">
                <b-select :value="d_chiefJudge" @change="chiefJudge_change" :disabled="currentEvent.isStarted" >
                    <option v-for="judge in d_chiefJudgeCandidates" :key="'cjc'+judge.id" :value="judge.id">{{ judge.name }}</option>
<!--                    <template #first>
                         <b-form-select-option :value="null" disabled>-- Choisissez le chef juge --</b-form-select-option>
                    </template>-->
                </b-select>
            </form-field>
        </collapse-panel>

        <collapse-panel title="Terrain" lazy>
            <form-field caption="Choix du terrain pour cette épreuve">
            <b-form-radio-group
                v-model="fieldForEvent"
                buttons
                button-variant="inline-secondary"
                :disabled="!currentEvent.isBriefingCompleted"
            >
                <field-editor
                v-for="field in d_fields"
                :key="field.field_number"
                :showSummary="true"
                :value="field"
                size="sm"
                :canSetHotState="fieldForEvent && fieldForEvent.field_number == field.field_number"
                :showSelector="!fieldForEvent || !fieldForEvent.isHot"
                :isSelected="fieldForEvent && fieldForEvent.field_number == field.field_number"
                @selection_change="fieldSelection_change"
                @hotState_change="fieldHotState_change"
                ></field-editor>
            </b-form-radio-group>
            </form-field>
        </collapse-panel>

        <collapse-panel title="Rappel des compétiteurs engagés" lazy collapsed>
            <detailed-competitors-list
                :items= "d_competitors"
                :canMove="currentEvent.ROUND.isBriefingCompleted && !currentEvent.isBriefingCompleted"
                :canAdd="false"
                :canSelect="false"
                :canEdit="false"
                :canRemove="false"
                :canGiveUp="false"
                :canChangePresence="false"
                :showName="true"
                :showOrder="true"
                @changePresence = "changeCompetitorPresence"
                @giveUp = "competitorGiveUp"
                @unGiveUp = "competitorUngiveUp"
                @move_up = "competitorMoveUp"
                @move_down = "competitorMoveDown"
          >
          </detailed-competitors-list>
        </collapse-panel>
    </div>
</template>

<script>
import { RST_COMPETITION_STAFF, RST_EVENT, RST_EVENT_STAFF, RST_FIELD, RST_COMPETITOR, RST_EVENT_COMPETITOR } from "@/LocalDB";
import DetailedStaffList from '@/components/DetailedStaffList';
import EventHeader from "./Components/EventHeader";
import EventsConfigurationHelper from "@/services/helpers/eventsConfigurationHelper";
import DetailedCompetitorsList from '@/components/DetailedCompetitorsList.vue';
import { CompetitorExecOrderRuleEnum } from '@/Constants';
import FieldEditor from '@/components/fieldEditor.vue';
import SynchroHelper from "@/services/helpers/synchroHelper"
import FormField from '../../../components/Panels/FormField.vue';
import CollapsePanel from '../../../components/Panels/CollapsePanel.vue';
import ModelViewsHelper from "@/services/store/models/ModelViews";
import InfoHelper from "../../../components/InfoHelper.vue";
//import { RST_ROUND_COMPETITOR } from '../../../LocalDB';

export default {
  components: { DetailedStaffList, EventHeader, DetailedCompetitorsList,/* LoadingOverlay,*/ FieldEditor, FormField, CollapsePanel, InfoHelper },
    data(){
        return {
            isLaunchable: false,
            confirmRestart: false,
            skipWorseNote: true,
            skipBestNote: false,
            canSetSkipWorseNoteRule: false,
            canSetSkipBestNoteRule: false,
            isLoading: false,

            fieldForEvent: null,

            d_fields: [],
            d_competitors: [],
            d_staffUsages: [],
            d_chiefJudgeCandidates: [],
            d_chiefJudge: null,

            //errors:[],
        };
    },
    computed: {
        isStarted(){
            return this.currentEvent.isStarted;
        },
        isCompleted(){
            return this.currentEvent.isCompleted;
        },
        canSetSkipNotesRules(){
            return this.canSetSkipWorseNoteRule || this.canSetSkipBestNoteRule;
        },
        fields(){
          return RST_FIELD.query().where("competition_id", this.currentEvent.competition_id).orderBy(f => f.field_number).get();
        },
     },
    methods:{

        async fieldSelection_change(e){
            await this.showSaver();
            try{
                if(!await this.ensureEvent())
                    throw "La création de l'épreuve a échoué";

                var upd = e.isSelected ? { field_number: e.field.field_number} : { field_number: null};
                RST_EVENT.update({ where: this.currentEvent.id, value: upd});
                var operationId = SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, upd, "SET_FIELD" );
                var result = await SynchroHelper.TryToSynchronizeAsync([operationId]);
                this.toastAsyncSaved(result, e.isSelected ? "Terrain sélectionné pour l'épreuve" : "Terrain de l'épreuve désélectionné");
                this.fieldForEvent = e.field;
            } catch(error){
                console.log("fieldSelection_change failure", error);
                this.toastSaveFailure(error);
            }
            this.hideSaver();
        },
        async fieldHotState_change(fieldNumber, newValue){
            await this.showSaver();
            try{
                if(!await this.ensureEvent())
                    throw "La création du l'épreuve a échoué";

                var data = { isHot: newValue };
                RST_FIELD.update({ where: this.currentEvent.id, data: data });
                var operationId = SynchroHelper.LogOperation("UPDATE", "RST_FIELD", this.currentEvent.id, data, "CHANGE_HOT_STATE");
                var result = await SynchroHelper.TryToSynchronizeAsync([operationId]);
                this.toastAsyncSaved(result, 'Etat "chaud" du terrain modifié');

            } catch(error){
                console.log('fieldHotState_change failure');
                this.toastSaveFailure(error);
            }
            this.hideSaver();
        },
        async refresh(){
            this.d_fields = RST_FIELD.query().where("competition_id", this.currentEvent.competition_id).orderBy(f => f.field_number).get();
            this.fieldForEvent = this.d_fields.find(f => f.field_number == this.currentEvent.field_number);
            if(!this.fieldForEvent || this.fieldForEvent == null)
                this.fieldForEvent = this.d_fields[0];

            this.d_chiefJudge = this.currentEvent.chiefJudge_staff_id

            await this.refreshStaff();

            await this.refreshCompetitors();
            var judgesCount = this.d_staffUsages.filter(item => !item.isShadow).length;
            this.isLaunchable = !this.currentEvent.hasErrors && this.currentEvent.canBeLaunchedByUser(this.currentUser.visa); //(errors.length == 0) // && this.currentEvent.ROUND.isBriefingCompleted;
            this.canSetSkipWorseNoteRule = judgesCount >= 5; // (judgesCount >= this.currentEvent.CATEGORY.RULE.minJudgesRequiredToSkipWorseNote);
            this.canSetSkipBestNoteRule = judgesCount >= 5; // (judgesCount >= this.currentEvent.CATEGORY.RULE.minJudgesRequiredToSkipBestNote);

        },
        async refreshStaff(event){
            if(!event || event == null){
                event = RST_EVENT.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("round_number", this.currentEvent.round_number)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where("event", this.currentEvent.event)
                            .first();
            }
            this.d_staffUsages = ModelViewsHelper
                    .getDetailedCompetitionStaffListItems(this.currentEvent.competition_id, event ? event.STAFF_MEMBERS : [])
                    .sort((a,b) => { return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0); });
            this.d_chiefJudgeCandidates = [{ id:null, staff_id:null, name:"Choisir"}].concat(this.d_staffUsages.filter(m => m.isJudge && !m.isShadow));
//            if(this.d_chiefJudgeCandidates.length >= 0 )

        },
        async refreshCompetitors(event){
            if(!event || event == null){
                event = RST_EVENT.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("round_number", this.currentEvent.round_number)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where("event", this.currentEvent.event)
                            .first();
            }
            if(event == null)
                event = this.currentEvent;

            var allCompetitors = RST_COMPETITOR.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where(c => !c.isWithdrawn && !c.isAbsent && !c.isForfeited)
                            .get()
                            .map(c => { return { ...c, ...c.COMPETITOR }});
            var evtCompetitors = RST_EVENT_COMPETITOR.query()
                            .where("competition_id", this.currentEvent.competition_id)
                            .where("level", this.currentEvent.level)
                            .where("category", this.currentEvent.category)
                            .where("round_number", this.currentEvent.round_number)
                            .where("event", this.currentEvent.event)
                            .orderBy('predicted_order')
                            .get();


            if(evtCompetitors.length == 0){
                //console.log('Sort required =>', event.ROUND.competitors_order_rule, allCompetitors);
                switch(event.ROUND.competitors_order_rule){
                    case CompetitorExecOrderRuleEnum.Registration:
                        //console.log('sort on registration order');
                        allCompetitors.sort((a,b) => this.compare(a.subscription_order , b.subscription_order) );
                        break;
                    case CompetitorExecOrderRuleEnum.Alea:
                        allCompetitors.sort((a,b) => this.compare(a.random_order, b.random_order));
                        break;
                    case CompetitorExecOrderRuleEnum.Alpha:
                        allCompetitors.sort((a,b) => this.compare(a.name, b.name, 'asc'));
                        break;
                    case CompetitorExecOrderRuleEnum.AlphaReverse:
                        allCompetitors.sort((a,b) => this.compare(a.name, b.name, 'desc'));
                        break;
                    case CompetitorExecOrderRuleEnum.PrevRoundReverse:
                        console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. PrevRoundReverse + "' à implémenter");
                        break;
                    case CompetitorExecOrderRuleEnum.Rank:
                        console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. Rank + "' à implémenter");
                        break;
                    case CompetitorExecOrderRuleEnum.RankReverse:
                        console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. RankReverse + "' à implémenter");
                        break;
                }
                allCompetitors = allCompetitors.map((cc, i) => {cc.predicted_order = i; return cc;})
                //console.log('après tri', allCompetitors);
            } else {
                allCompetitors.map(cc => {
                    var ec = evtCompetitors.find(e => e.competitor_id == cc.competitor_id); 
                    if(ec != null)
                    {
                        cc.predicted_order = ec.predicted_order;
                    } else {
                        cc.predicted_order = allCompetitors.length;
                    }
                })
                allCompetitors.sort((a,b)=> this.compare(a.predicted_order,b.predicted_order));
            }

            var o = 0;
            var ret = allCompetitors.map(cc => {
                var ord = allCompetitors.length;
                var ec = evtCompetitors.find(e => e.competitor_id == cc.competitor_id) || new RST_EVENT_COMPETITOR();
                if(!ec.isForfeit && !ec.isAbsent && !ec.isWidthdrawn)
                {
                    o++;
                    ord = o;
                } else {
                    ec.executionStatus = RST_EVENT_COMPETITOR.ExecutionStatus_Completed;
                }
                return { ...cc, ...ec, competitor_id: cc.competitor_id, predicted_order: ord, effective_order: ord, order: ord};
            });

          this.d_competitors = ret;
        },
        async chiefJudge_change(newValue){
            console.log('chiefJudge_change', newValue);
            await this.showSaver();
            try{
                console.log('chiefjuge_change newValue', newValue);
                var result = await EventsConfigurationHelper.changeEventChiefJudgeAsync(this.currentEvent.competition_id, this.currentEvent.round_number, this.currentEvent.level, this.currentEvent.category, this.currentEvent.event, newValue);
                this.currentEvent.chiefJudge_staff_id = newValue;
                this.toastAsyncSaved(result, "Chef juge modifié");
                this.isLaunchable = !this.currentEvent.hasErrors && this.currentEvent.canBeLaunchedByUser(this.currentUser.visa); //(errors.length == 0) // && this.currentEvent.ROUND.isBriefingCompleted;
                this.d_chiefJudge = newValue;
            }catch(error){
                console.log('chiefJudge_change failure');
                this.toastSaveFailure(error);
            }
            this.$hideSaver();
        },

        async roleChanged(e){
            await this.selectForEvent(e.roleName, e.memberId);
        },
        async selectForEvent(asFunction, staffMemberId)
        {
            console.log("asFunction / staffMemberId", asFunction, staffMemberId);
            await this.showSaver();
            //try{
                //await this.ensureEvent();
                var operationIds = [];
                //EventsConfigurationHelper.setStaffMemberRoleForEvent(this.currentCompetition_id);
                var member = RST_EVENT_STAFF.query()
                                    .where("competition_id", this.currentEvent.competition_id)
                                    .where("round_number", this.currentEvent.round_number)
                                    .where("level", this.currentEvent.level)
                                    .where("category", this.currentEvent.category)
                                    .where("event", this.currentEvent.event)
                                    .where("staff_id", staffMemberId)
                                    .first();
                                    
                var change = {
                    id: (member && member != null) ? member.id : this.newGuid(),
                    round_number: this.currentEvent.round_number,
                    level: this.currentEvent.level,
                    category: this.currentEvent.category,
                    event: this.currentEvent.event,
                    staff_id: (member && member != null) ? member.staff_id : null,
                    competition_id: this.currentEvent.competition_id,
                    event_id: this.currentEvent.event_id,
                    isShadow: (asFunction === 'judgeShadow' || asFunction === 'fieldDirectorShadow' || asFunction === 'scorerShadow') && (!member || !member.isShadow),
                    isJudge: (asFunction === 'judge' || asFunction === 'judgeShadow') && (!member || !member.isJudge),
                    isFieldDirector: (asFunction === 'fieldDirector' || asFunction === 'fieldDirectorShadow') && (!member || !member.isFieldDirector),
                    isScorer: (asFunction === 'scorer' || asFunction === 'scorerShadow') && (!member || !member.isScorer),
                    isTechJudge: (asFunction === 'techJudge' || asFunction === 'techJudgeShadow') && (!member || !member.isTechJudge),
                    isArtJudge: (asFunction === 'artJudge' || asFunction === 'artJudgeShadow') && (!member || !member.isArtJudge)
                };
                
                console.log(change, member);
                if(change.isJudge || change.isFieldDirector || change.isScorer || change.isTechJudge || change.isArtJudge){
                    if(!member || member == null)
                    {
                        console.log('new staff member');
                        var source = RST_COMPETITION_STAFF.query()
                                        .where("competition_id", this.currentEvent.competition_id)
                                        .where("staff_id", staffMemberId)
                                        .first();
                        var newData = {...change, staff_id: source.staff_id };
                        /*    id: this.newGuid(),
                            round_number: this.currentEvent.round_number,
                            level: this.currentEvent.level,
                            category: this.currentEvent.category,
                            event: this.currentEvent.event,
                            staff_id: source.staff_id,
                            competition_id: source.competition_id,
                            event_id: this.currentEvent.event_id
                        };*/
                        console.log('newData', newData);
                        RST_EVENT_STAFF.insert({ data: newData });
                        operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT_STAFF", null, newData));
                        member = newData;
                        change.id = member.id;
                    } else {
                        RST_EVENT_STAFF.update({ where: member.id, data : change });
                        operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT_STAFF", member.id, change));
                    }
                } else {
                    if(member && member != null)
                    {
                        RST_EVENT_STAFF.delete(member.id);
                        operationIds.push(SynchroHelper.LogOperation("DELETE", "RST_EVENT_STAFF", member.id));
                    }
                }
                console.log('operationIds', operationIds);
                var result = SynchroHelper.TryToSynchronizeAsync(operationIds);

                this.toastAsyncSaved(result, "Modification de l'équipe effectuée");
                await this.refreshStaff();
                console.log('Checking Chief Judge', this.d_chiefJudgeCandidates, this.currentEvent.chiefJudge_staff_id);
                console.log('check eq.', this.currentEvent.chiefJudge_staff_id, member.staff_id, );
                //if(this.currentEvent.chiefJudge_staff_id == member.staff_id){
                //if(this.currentEvent.chiefJudge_staff_id != null && this.d_chiefJudgeCandidates.findIndex(cj => /*cj.staff_id*/ cj.staff_id == this.currentEvent.chiefJudge_staff_id ) < 0)
                    result = await this.chiefJudge_change(null);
                //}
           // } catch(error) {
           //     this.toastSaveFailure(error);
           // }
            this.$hideSaver();
        },
        changeCompetitorPresence(competitor){
          this.$showRefresher()
            .then(() => {
              console.log("changeCompetitorPresence", competitor);
              this.$hideRefresher();
            });
        },
        competitorGiveUp(competitor){
          this.$showRefresher()
            .then(() => {
              console.log("ompetitorGiveUp", competitor);
              this.$hideRefresher();
            });
        },
        competitorUngiveUp(competitor){
          this.$showRefresher()
            .then(() => {
              console.log("competitorUngiveUp", competitor);
              this.$hideRefresher();
            });
        },
        competitorMoveUp(competitor){
            console.log('competitorMoveUp => NOT IMPLEMENTED', competitor);
        },
        competitorMoveDown(competitor){
            console.log('competitorMoveDown => NOT IMPLEMENTED', competitor);
        },
        async razEvent(){
            console.log('razEvent');
            await this.$showSaver();
            await EventsConfigurationHelper
                .razEventAsync(this.currentCompetition_id,
                          this.currentRound_number,
                          this.currentLevel_code,
                          this.currentCategory_code,
                          this.currentEvent_code);
            this.$hideSaver();
            await this.gotoEvent();
        },
        async initEventRun(){
            console.log('initEventRun');
            await this.$showSaver();
            await EventsConfigurationHelper
                .initEventRun(this.currentCompetition_id,
                          this.currentRound_number,
                          this.currentLevel_code,
                          this.currentCategory_code,
                          this.currentEvent_code,
                          this.d_competitors);
            this.hideSaver();
            await this.gotoEvent();
        },
        async gotoEvent(){
            console.log('gotoEvents');
            await this.showLoader();
        

            this.$router.push({ name: 'EventRun'});
        },
    },
    async mounted(){
      await this.refresh();
      this.$hideLoader();
    },
}

</script>

<style scoped>
    .location.selective { font-weight:bolder; font-size:1.4rem; }
    .roundNo { font-weight: bolder; font-size: 1.2rem; }
    .level { font-weight: bolder;   }
    .category { font-weight: bolder;  }
    .format { font-weight: bolder; }
    .inactif { color: darkgray; cursor: default; }
    .infos-category{ border:black 1px solid; border-radius: 4px; margin:10px; padding:0.5rem;}
    /*.actors-list{ display: flex; align-content:center; align-items: center; margin-top:0.3rem; flex-wrap: wrap; }*/
    .actors-list {
        width:85%;
        display:grid;
        grid-template-columns:0px, 4fr, repeat(5,70px);
    }
    .competitors-list{ flex-direction: column;}
    .actors-list > div { margin: 0; /*min-width:100px; max-width:100px;*/ padding: 2px 5px;}
    .competitors { font-weight: bolder; text-decoration: blue 2px underline; margin-top:1rem;}
    .judges { font-weight:bolder; text-decoration: blue 2px underline; margin-top:1rem;}
    .fieldDirectors  { font-weight:bolder; text-decoration: blue 2px underline; margin-top:1rem;}
    .btn, .btn-event { margin-top: 10px; margin-bottom: 10px;}
    .text { margin-top:20px; margin-bottom:20px;}
    .dialogTitle{ font-weight: bold; }
    .warning{ color:orangered; }
    /*.btn-event{  background-color:lightsalmon; text-decoration: none; border-radius:5px; min-height: 2rem; height:2rem; padding: 0.5rem 0.1rem;}*/
    .btn-event.completed { border:green 1px solid; background-color:lightgreen;}
    .btn-event.toBeCompleted { border: darkorange 2px solid; background-color:lightsalmon; }
    .event-name { font-weight: bolder; }
    .btn-results { border: green 2px solid; background-color:greenyellow; color:green; font-weight: bolder; font-size: 1.5rem;}
    .header{ grid-row:1 }
    .judgeName{ grid-column: 2;  }
    .isJudge{ grid-column: 3;}
    .isJudgeShadow{ grid-column: 4;}
    .isFieldDirector{ grid-column: 5;}
    .isFieldDirectorShadow{ grid-column: 6; }
    .isScorer{ grid-column: 7; }
    .judgeName{ grid-column: 2; border: solid 1px lightgray; }
/*  .judgeName
    .isJudge{ grid-column: 2; border: solid 1px lightgray; }
    .isJudgeShadow{ grid-column: 3;border: solid 1px lightgray;}
    .isFieldDirector{ grid-column: 4;border: solid 1px lightgray; }
    .isFieldDirectorShadow{ grid-column: 5;border: solid 1px lightgray; }
    .isScorer{ grid-column: 6; border: solid 1px lightgray;}
*/
    .actors-list-container{ display: flex; justify-content: center; flex-wrap: wrap; margin-top: 15px; width:100% }
    .actors-list.header > div { border: none;}

        .header > .judgName{  vertical-align: bottom; }
        .header > .isJudge::after{ content: 'Juge'; text-align:center; vertical-align: bottom;}
        .header > .isJudgeShadow::after{ content: 'Juge ombre';text-align:center; vertical-align: bottom;}
        .header > .isFieldDirector::after{ content: 'Directeur de Terrain';text-align:center; vertical-align: bottom;}
        .header > .isFieldDirectorShadow::after{ content: 'Directeur de Terrain ombre';text-align:center; vertical-align: bottom;}
        .header > .isScorer::after{ content: 'Scoreur'; text-align:center; vertical-align: bottom;}

    .judgeName { min-width:7fr; max-width:7fr; /*min-width:150px; max-width:150px;*/ border:none; border-right: solid 1px lightgray; }
    .header { font-size: 0.8em; border:none; border-bottom: solid 1px lightgray; text-align: center;}

    input[type='checkbox']{ width:20px; height:20px;}
/*    input[type='checkbox']::after{ content: 'N'};
    input[type='checkbox']:checked::after{ content: 'Y'};*/

    @media screen and (max-width: 767px) and (orientation: portrait) {

        .actors-list {
            /*display:grid;*/
            grid-template-columns: 0px, 6fr, repeat(5,70px);
        }
        .actors-list > div { margin: 0; padding: 4px 12px; /* min-width:20px; max-width:20px;*/ text-align:center;}
        .actors-list > div.judgeName{ /*min-width: 100px; max-width:100px;*/ min-width:7fr; max-width:7fr; text-align:left;}

        .header > .isJudge::after{ content: 'J'; }
        .header > .isJudgeShadow::after{ content: 'Jo';}
        .header > .isFieldDirector::after{ content: 'DT';}
        .header > .isFieldDirectorShadow::after{ content: 'DTo';}
        .header > .isScorer::after{ content: 'S'; }
    }

</style>
